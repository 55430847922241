.hvrbox,
.hvrbox * {
    box-sizing: border-box;
}
.hvrbox {
    position: relative;
    display: inline-block;
    overflow: hidden;
    max-width: 100%;
    height: auto;
    border: 0px solid;
}

.hvrbox .hvrbox-layer_bottom {
    display: block;
}
.hvrbox .hvrbox-layer_top {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    color: #fff;
    padding: 15px;
    -moz-transition: all 0.4s ease-in-out 0s;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -ms-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.hvrbox:hover .hvrbox-layer_top,
.hvrbox.active .hvrbox-layer_top {
    opacity: 1;
}
.hvrbox .hvrbox-text {
    text-align: center;
    font-size: 18px;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.hvrbox .hvrbox-text_mobile {
    font-size: 15px;
    border-top: 1px solid rgb(179, 179, 179); /* for old browsers */
    border-top: 1px solid rgba(179, 179, 179, 0.7);
    margin-top: 5px;
    padding-top: 2px;
    display: none;
}
.hvrbox.active .hvrbox-text_mobile {
    display: block;
}

.footer {
    /* height: 150px; */
    padding-top: 20px;
    flex-shrink: 0;
    background-color: #f5f5f5;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.snackbar-textColor {
    color: black;
}
.snackbar-progress {
    background: black;
}

.snackbar-toast {
    background: black;
}
.carousel .slide {
    background-color: #f3f6f8 !important;
}
